import lodashGet from "lodash/get";

// This file reads the contents of the '#javascript-data' JSON script tag
// and makes that data available to includers. Includers can access the
// data using the function `get`.
//
// Example usage in a file like layout/main.js:
//
// ```
//   import pageData from 'common/page-data';
//
//   console.log(
//     pageData.get('partner.code')
//   );
//   # => 'adwerx'
//
//   console.log(
//     pageData.get('mixpanelData', { defaultMixpanelData: true })
//   );
//   # => { defaultMixpanelData: true }
// ```

const el = document.getElementById("javascript-data");
const data = el ? JSON.parse(el.innerHTML) : {};

export default {
  // `get` is a wrapper around lodash#get.
  // This `get` wrapper supports the path argument (an array or a dot-separated string)
  // and the defaultValue argument.
  //
  // For example argument values, see the lodash `get` docs:
  // https://lodash.com/docs/4.17.4#get
  get: (...args) => lodashGet(data, ...args)
};
