import queryString from "query-string";

const queryStringParams = queryString.parse(window.location.search);

const mixpanelLastTouch = {};

mixpanelLastTouch.registerCampaignParams = mixpanel => {
  const utmKeywords = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term"
  ];
  const campaignKeywords = utmKeywords.concat([
    "ait_page",
    "ait_link",
    "ait_blog"
  ]);

  const params = {};
  let utmSet = false;

  for (let index = 0; index < campaignKeywords.length; index += 1) {
    const key = campaignKeywords[index];
    let kw = queryStringParams[key];
    if (typeof kw !== "undefined" && kw.length) {
      // handle cases when the UTM parameter is passed twice
      if (kw.constructor === Array) {
        kw = kw[0]; // eslint-disable-line prefer-destructuring
      }
      params[`${campaignKeywords[index]}_last`] = kw;
      if (!utmSet && key.indexOf("utm") >= 0) {
        utmSet = true;
      }
    }
  }

  if (Object.keys(params).length > 0) {
    if (utmSet) {
      params.utm_last_time = new Date().toISOString();
      for (let index = 0; index < utmKeywords.length; index += 1) {
        const key = utmKeywords[index];
        const keyLast = `${key}_last`;
        if (!(keyLast in params)) {
          params[keyLast] = "";
        }
      }
    }
    mixpanel.register(params);
  }
};

mixpanelLastTouch.registerAitParams = (mixpanel, aitPage, aitLink) => {
  mixpanel.register({
    ait_page_last: aitPage,
    ait_link_last: aitLink
  });
};

export default mixpanelLastTouch;
