// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__globalBanner___1aee1{background-color:#e6a700}", "",{"version":3,"sources":["/workspace/app/javascript/layout/global-banner/styles.module.scss"],"names":[],"mappings":"AAEA,qCACE,wBAHe","file":"styles.module.scss","sourcesContent":["$gold-supernova: #E6A700;\n\n.globalBanner {\n  background-color: $gold-supernova;\n}\n"]}]);
// Exports
exports.locals = {
	"globalBanner": "styles-module__globalBanner___1aee1"
};
module.exports = exports;
