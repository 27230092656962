import mixpanel from "mixpanel-browser";
import mixpanelLastTouch from "./mixpanel-last-touch";

// if the token is falsy, mixpanel.track throws errors
mixpanel.init(process.env.MIXPANEL_PUBLIC_TOKEN || "please dont break", {
  // if you set upgrade to be true, the library will check for
  // a cookie from our old js library and import super
  // properties from it, then the old cookie is deleted
  // The upgrade config option only works in the initialization,
  // so make sure you set it when you create the library.
  // https://mixpanel.com/help/reference/javascript-full-api-reference
  upgrade: true,
  loaded() {
    mixpanel.__track = mixpanel.track;
    mixpanel.track = function trackWithCallbackTimeout(
      event,
      properties,
      callback
    ) {
      let ran = false;
      const timeoutMs = 3000;
      let onceable;

      console.debug(
        `Executing timeout version of mixpanel.track('${event}') with ${timeoutMs}ms timeout...`
      );

      if (typeof callback === "function") {
        onceable = function callbackOnceGuard() {
          if (!ran) {
            ran = true;
            callback.call(this, event, properties, callback);
          }
        };
        setTimeout(() => {
          if (!ran) {
            console.error(`mixpanel.track timed out after ${timeoutMs}ms`);
          }

          onceable.call(mixpanel, false, { event, properties });
        }, timeoutMs);
      }
      mixpanel.__track(event, properties, onceable);
    };
  }
});

if (window._DATADOG_SYNTHETICS_BROWSER === true) {
  mixpanel.opt_out_tracking();
}

mixpanelLastTouch.registerCampaignParams(mixpanel);

export default mixpanel;
