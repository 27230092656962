const pixelService = {};

// This places the specified pixels on the page one after the other.
// A brief timeout occurs between them.
//
// Example `pixels` argument:
// [{
//   id: '0',
//   pixelName: '162036',
//   pixelContent: '&lt;script src=&quot;http://pixely-pixel-pixel.com?id=162036&amp;t=1&quot;
//                  type=&quot;text/javascript&quot;&gt;&lt;/script&gt;'
// }]

function decodeJsSafeHTML(str) {
  return str
    .toString()
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"');
}

function includePixel(pixelId, pixelName, pixelContent) {
  const uniqueId = `pixel_${pixelId}_${pixelName}`;
  let iframe = document.getElementById(uniqueId);
  let iframeDoc;
  let iframeContent;

  if (!iframe && document.createElement) {
    iframe = document.createElement("iframe");
    iframe.id = uniqueId;
    iframe.name = uniqueId;
    iframe.width = 0;
    iframe.height = 0;
    iframe.style.display = "none";

    document.body.appendChild(iframe);

    if (iframe.contentDocument) {
      iframeDoc = iframe.contentDocument;
    } else if (iframe.contentWindow) {
      iframeDoc = iframe.contentWindow.document;
    } else if (window.frames[iframe.name]) {
      iframeDoc = window.frames[iframe.name].document;
    }

    if (iframeDoc) {
      iframeContent = decodeJsSafeHTML(pixelContent);
      iframeDoc.open();
      iframeDoc.write(iframeContent);
      iframeDoc.close();
    }
  }
}

pixelService.includePixels = function includePixels(pixels) {
  return pixels.forEach((pixel, i) => {
    setTimeout(() => {
      includePixel(pixel.id, pixel.pixel_name, pixel.pixel_content);
    }, (i + 1) * 10);
  });
};

export default pixelService;
