import modernizr from "modernizr";

// Custom modernizr tests, like for ios.
//
// Based on examples here: https://gist.github.com/danott/855078

modernizr.addTest("ipad", () => !!window.navigator.userAgent.match(/iPad/i));

modernizr.addTest(
  "iphone",
  () => !!window.navigator.userAgent.match(/iPhone/i)
);

modernizr.addTest("ipod", () => !!window.navigator.userAgent.match(/iPod/i));

modernizr.addTest(
  "ios",
  () => modernizr.ipad || modernizr.ipod || modernizr.iphone
);

modernizr.addTest(
  "md_up",
  () => window.matchMedia("(min-width: 768px)").matches
);
