import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import request from "common/request";
import styles from "./styles.module.scss";

const propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  dismissible: PropTypes.bool
};

const defaultProps = {
  dismissible: false
};

class GlobalBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { closed: false };
    this.close = this.close.bind(this);
  }
  close() {
    request.post("/dismiss_message", { id: this.props.id });
    this.setState({ closed: true });
  }
  render() {
    return (
      <Collapse isOpen={!this.state.closed}>
        <div className={`w-100 ${styles.globalBanner}`}>
          <div className="p-2 d-flex align-items-center justify-content-center">
            <div dangerouslySetInnerHTML={{ __html: this.props.message }} />
            {this.props.dismissible && (
              <div className="ml-2 ml-sm-3">
                <button
                  onClick={this.close}
                  className="btn btn-info text-uppercase"
                >
                  Dismiss
                </button>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    );
  }
}

GlobalBanner.propTypes = propTypes;
GlobalBanner.defaultProps = defaultProps;

export default GlobalBanner;
