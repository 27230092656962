import $ from "jquery";
import mixpanel from "common/mixpanel";

const $emailLoginToggle = $(".email-login-toggle");
const $toggleIcon = $emailLoginToggle.find("i");
const $emailLogin = $(".email-login-modal");

function toggleEmailLogin(event) {
  event.preventDefault();
  $emailLogin.toggle();
  $toggleIcon.toggleClass("fa-angle-down fa-angle-up");
}

$emailLoginToggle.on("click", toggleEmailLogin);

$(document).on("click", ".sso-login a", () => {
  mixpanel.track("Account.SSOClickButton");
});
