module.exports = {
	"en": {
		"errors.messages.expired": "has expired, please request a new one",
		"errors.messages.reset_token_expired": "Your reset password token has expired. Please request another password reset.",
		"errors.messages.reset_token_invalid": "Your reset password token is invalid. Have you already changed your password? If not, please request another password reset.",
		"errors.messages.reset_token_blank": "You don't have a reset password token. Please follow the full URL from the password reset email, or request another password reset.",
		"errors.messages.pw_confirm_mismatch": "Password confirmation does not match.",
		"errors.messages.not_found": "not found",
		"errors.messages.already_confirmed": "was already confirmed, please try signing in",
		"errors.messages.not_locked": "was not locked",
		"errors.messages.not_saved.one": "Please correct the following error:",
		"errors.messages.not_saved.other": "Please correct the following %{count} errors:",
		"errors.messages.failed_signin": "We could not sign you in with the provided credentials.",
		"errors.messages.pw_reset": "Check your email for password reset instructions",
		"errors.messages.unknown_email": "Unknown email address. Please try again.",
		"devise.failure.already_authenticated": "You are already signed in.",
		"devise.failure.unauthenticated": "You need to sign in or sign up before continuing.",
		"devise.failure.unconfirmed": "You have to confirm your account before continuing.",
		"devise.failure.locked": "Your account is locked.",
		"devise.failure.invalid": "Invalid email or password.",
		"devise.failure.invalid_token": "Invalid authentication token.",
		"devise.failure.timeout": "Your session expired, please sign in again to continue.",
		"devise.failure.inactive": "Your account was not activated yet.",
		"devise.sessions.signed_in": "Signed in successfully.",
		"devise.sessions.signed_out": "Signed out successfully.",
		"devise.passwords.send_instructions": "You will receive an email with instructions about how to reset your password in a few minutes.",
		"devise.passwords.updated": "Your password was successfully changed.",
		"devise.passwords.send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
		"devise.passwords.no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
		"devise.confirmations.send_instructions": "You will receive an email with instructions about how to confirm your account in a few minutes.",
		"devise.confirmations.send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions about how to confirm your account in a few minutes.",
		"devise.confirmations.confirmed": "Your account was successfully confirmed. You are now signed in.",
		"devise.registrations.signed_up": "Welcome! You have signed up successfully.",
		"devise.registrations.signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please open the link to activate your account.",
		"devise.registrations.signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
		"devise.registrations.signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
		"devise.registrations.updated": "You updated your account successfully.",
		"devise.registrations.update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and click on the confirm link to finalize confirming your new email address.",
		"devise.registrations.destroyed": "Bye! Your account was successfully canceled. We hope to see you again soon.",
		"devise.unlocks.send_instructions": "You will receive an email with instructions about how to unlock your account in a few minutes.",
		"devise.unlocks.unlocked": "Your account has been unlocked successfully. Please sign in to continue.",
		"devise.unlocks.send_paranoid_instructions": "If your account exists, you will receive an email with instructions about how to unlock it in a few minutes.",
		"devise.omniauth_callbacks.success": "Successfully authenticated from %{kind} account.",
		"devise.omniauth_callbacks.failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
		"devise.mailer.confirmation_instructions.subject": "Confirmation Instructions",
		"devise.mailer.reset_password_instructions.subject": "Reset Password Instructions",
		"devise.mailer.unlock_instructions.subject": "Unlock Instructions",
		"receipt_email.timeline_notice.subject_count.one": "it has",
		"receipt_email.timeline_notice.subject_count.other": "they have",
		"receipt_email.timeline_notice.mortgage_subject_count.one": "it will",
		"receipt_email.timeline_notice.mortgage_subject_count.other": "they will",
		"contacts.header.recruiting": "Who do you want to recruit?",
		"contacts.header.real_estate_broker_recruiting": "Who do you want to recruit?",
		"contacts.header.tv_sphere": "Who's in your audience?",
		"contacts.header.other": "Create your audience",
		"contacts.ad_viewers.recruiting": "the agents in your database",
		"contacts.ad_viewers.real_estate_broker_recruiting": "the agents in your database",
		"contacts.ad_viewers.tv_sphere": "who's in your audience",
		"contacts.ad_viewers.other": "who's in your sphere",
		"contacts.impression_delivery_description.tv_sphere": "when they watch their favorite shows on streaming TV",
		"contacts.impression_delivery_description.other": "when they browse the Web, Mobile apps, and Facebook",
		"contacts.instructions.recruiting": "Get started today by exporting a list. For best results, include business and personal emails, as well as phone numbers.",
		"contacts.instructions.real_estate_broker_recruiting": "Get started today by exporting a list. For best results, include business and personal emails, as well as phone numbers.",
		"contacts.instructions.tv_sphere": "Your audience probably includes friends, family, past clients, and more. Get started by exporting a list!",
		"contacts.instructions.other": "Your sphere probably includes friends, family, past clients, and more. Get started by exporting a list!",
		"contacts.ad_noun_descriptor.tv_sphere": "commercial",
		"contacts.ad_noun_descriptor.other": "ad",
		"contacts.recommended_contact_quantity.tv_sphere": "at least 5000",
		"contacts.recommended_contact_quantity.other": "300-500",
		"tv_preview.title.one": "Choose your TV commercial",
		"tv_preview.title.other": "Choose your TV commercials",
		"launch.preview_ad_label.generic": "Ad Preview",
		"launch.preview_ad_label.has_master": "Master Ad Preview",
		"launch.edit_ad_label.generic": "Edit",
		"launch.edit_ad_label.has_master": "Edit Master Ad",
		"launch.purchase_button_label.generic": "Purchase & Launch Ad",
		"launch.purchase_button_label.tv": "Purchase & Launch Commercial",
		"errors.messages.unanalyzed": "must be analyzed",
		"errors.messages.unavailable": "is unavailable",
		"errors.messages.invalid_radius": "invalid radius",
		"results.faq.questions.tv": [
			{
				"prompt": "Who will see my commercial?",
				"answer": "People in the areas you target streaming premium TV shows and movies on services like SlingTV, DirecTV Now, Hulu and more will see your commercial when they watch on devices like SmartTVs, Apple TV, Amazon Fire Stick, Roku and more.\n"
			},
			{
				"prompt": "Which channels and shows does my commercial air on?",
				"answer": "Your commercial will air on dozens of possible channels through various streaming TV services.\n"
			},
			{
				"prompt": "Will I see my commercial?",
				"answer": "We can't guarantee you will see your commercial, however if you watch TV on supported streaming services in your target area, you may see your commercial.\n"
			},
			{
				"prompt": "How long does it run?",
				"answer": "Your commercial runs for the duration of your billing cycle, and will be viewed the number of times you select.\n"
			},
			{
				"prompt": "Can I switch from one commercial to another?",
				"answer": "Yes, click \"Edit Commercial\", to change the commercial you’re running."
			}
		],
		"results.faq.questions.tv_sphere": [
			{
				"prompt": "Who will see my commercial?",
				"answer": "People in your audience list streaming premium TV shows and movies on services like SlingTV, DirecTV Now, Hulu and more will see your commercial when they watch on devices like SmartTVs, Apple TV, Amazon Fire Stick, Roku and more.\n"
			},
			{
				"prompt": "Which channels and shows does my commercial air on?",
				"answer": "Your commercial will air on dozens of possible channels through various streaming TV services.\n"
			},
			{
				"prompt": "Will I see my commercial?",
				"answer": "We can't guarantee you will see your commercial, however if you watch TV on supported streaming services and your email address is in your audience list, you may see your commercial.\n"
			},
			{
				"prompt": "How long does it run?",
				"answer": "Your commercial runs for the duration of your billing cycle, and will be viewed the number of times you select.\n"
			}
		],
		"results.plans_duration.tv": "commercial",
		"results.stats.how_many_prompt": "How many times has your ad been %{past_participle}?",
		"results.stats.tv_prompt": "Your Streaming TV Commercial Performance",
		"zip_code.one": "zip code",
		"zip_code.other": "zip codes",
		"zip.one": "zip",
		"zip.other": "zips",
		"impressions.tv": "Airings",
		"impressions.real_estate_broker_recruiting": "Plan",
		"impressions.recruiting": "Plan",
		"impressions.other": "Views",
		"impression_quantities.headline.tv": "How many times do you want your TV commercial to be shown?",
		"impression_quantities.headline.tv_sphere": "What's your monthly budget?",
		"impression_quantities.headline.real_estate_broker_recruiting": "How often do you want people to see your ad?",
		"impression_quantities.headline.recruiting": "How often do you want people to see your ad?",
		"impression_quantities.headline.brand_radius": "How often do you want people to see your ad?",
		"impression_quantities.headline.video": "How many times do you want your video to be shown?",
		"impression_quantities.headline.video_referral": "How many times do you want your video to be shown?",
		"impression_quantities.validation_message.tv.below_minimum": "Add more views to reach more viewers in the area you're targeting with your commercial!",
		"impression_quantities.validation_message.tv.above_maximum": "The population of the areas you've targeted may be too small for the amount of commercial views selected. Select more target areas to improve delivery.",
		"impression_quantities.validation_message.tv_sphere.below_minimum": "Add more views to reach your entire audience.",
		"impression_quantities.validation_message.tv_sphere.above_maximum": "The size of the audience you've targeted may be too small for the amount of commercial views selected. Add more contacts to improve delivery.",
		"impression_quantities.validation_message.brand_radius.below_minimum": "Add more views to reach more viewers in the area you're targeting with your ad!",
		"impression_quantities.validation_message.brand_radius.above_maximum": "Your selected areas might be too small for your selected ad views. Please consider adding more areas or increasing the radius size.",
		"impression_quantities.validation_message.video.below_minimum": "Add more views to reach more viewers in the area you're targeting with your video!",
		"impression_quantities.validation_message.video.above_maximum": "The population of the areas you've targeted may be too small for the amount of video views selected. Select more target areas to improve delivery.",
		"impression_quantities.label.tv": "commercial views",
		"impression_quantities.label.tv_sphere": "views/month",
		"impression_quantities.label.real_estate_broker_recruiting": "views/mo",
		"impression_quantities.label.recruiting": "views/mo",
		"impression_quantities.label.brand_radius": "views/mo",
		"impression_quantities.label.retargeting": "views/month",
		"impression_quantities.label.video": "views/month",
		"impression_quantities.label.video_referral": "views/month",
		"impression_quantities.note.retargeting": "Note: Ideally, your total monthly view is 10-15 times your website's total monthly unique visitors.\n",
		"product_page.search_header.generic": "Look up your business to see the ad that we’ve created for you",
		"product_page.search_header.tv": "Run your TV commercial for as little as %{price}/month.",
		"product_page.search_header.tv_zip": "Claim your zip codes",
		"product_page.search_subheader.generic": "Set up your ad campaign in minutes. Only $99/month for 2,500 ad views.",
		"product_page.search_subheader.tv": "Enter your name and location to watch your personalized TV commercials.",
		"product_page.search_subheader.tv_zip": "Enter your name and location to watch your personalized TV commercials.",
		"product_page.how_it_works.auto_designed_ads.title.generic": "Automatically designed ads",
		"product_page.how_it_works.auto_designed_ads.body.generic": "Use our simple template to create perfectly sized ads for Facebook, the web and mobile apps. You can edit your ad any time.",
		"product_page.how_it_works.advertise_everywhere.title.generic": "Advertise everywhere",
		"product_page.how_it_works.advertise_everywhere.body.generic": "Your ads will be shown on Facebook, top web sites and popular mobile apps.<br />%{sample_sites_link}",
		"product_page.how_it_works.directs_to_your_website.title.generic": "Send visitors to your website",
		"product_page.how_it_works.directs_to_your_website.body.generic": "Your ad links to your website so that people who click on it learn more about your business.",
		"product_page.how_it_works.campaign_results.title.generic": "View your campaign results",
		"product_page.how_it_works.campaign_results.body.generic": "You'll receive email reports about your ad campaign's progress, and you'll have a dashboard to see your results and archive them for future reference.",
		"radius.title.tv": "Streaming TV advertising is currently available in your area!",
		"radius.title.brand_radius": "Drop pins where you'd like your ads to run",
		"radius.disclaimer.tv": "Your streaming TV commercial will air in United States zip codes selected by each target radius.",
		"radius.disclaimer.brand_radius": "Facebook ads target a 15-mile radius from the center of your chosen area. On the Web, your ad will target zip codes selected by each target radius.\n",
		"radius.button_text.tv": "Next: Select Views",
		"radius.button_text.brand_radius": "Next: Review Ad Design",
		"concerns.campaign_with_brand_ad_module.default_phone": "555.555.5555",
		"campaign.ad_noun_descriptor": "ad",
		"campaign.renewal_modal_cta": "Re-enable Renewal",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you’ve selected is too small to run a campaign. Please select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States.",
		"docusign.unverified": "Oops, your account is not linked! Click the link below for more information.",
		"areas_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the zip code you entered is not supported.",
		"areas_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"areas_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"areas_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"areas_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"areas_campaign.city_state_placeholder": "e.g. Durham, NC",
		"areas_campaign.city_state_label": "Your city, state",
		"areas_campaign.notification_emails.zip_text": "Zip Code",
		"areas_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"areas_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"areas_campaign.summary_label": "Zip Code(s)",
		"areas_campaign.billing_zip": "Billing Zip Code",
		"referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"realtor_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the zip code you entered is not supported.",
		"realtor_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"realtor_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"realtor_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"realtor_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"realtor_campaign.city_state_placeholder": "e.g. Durham, NC",
		"realtor_campaign.city_state_label": "Your city, state",
		"realtor_campaign.notification_emails.zip_text": "Zip Code",
		"realtor_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"realtor_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"realtor_campaign.summary_label": "Zip Code(s)",
		"realtor_campaign.billing_zip": "Billing Zip Code",
		"digital_out_of_home_zip_campaign.campaign_postal_code_dto.error.invalid_code": "No locations available in this zip code.",
		"digital_out_of_home_zip_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"digital_out_of_home_zip_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"digital_out_of_home_zip_campaign.city_state_placeholder": "e.g. Durham, NC",
		"digital_out_of_home_zip_campaign.city_state_label": "Your city, state",
		"digital_out_of_home_zip_campaign.notification_emails.zip_text": "Zip Code",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"digital_out_of_home_zip_campaign.summary_label": "Zip Code(s)",
		"digital_out_of_home_zip_campaign.billing_zip": "Billing Zip Code",
		"mortgage_consumer_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the zip code you entered is not supported.",
		"mortgage_consumer_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"mortgage_consumer_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"mortgage_consumer_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"mortgage_consumer_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"mortgage_consumer_campaign.city_state_placeholder": "e.g. Durham, NC",
		"mortgage_consumer_campaign.city_state_label": "Your city, state",
		"mortgage_consumer_campaign.notification_emails.zip_text": "Zip Code",
		"mortgage_consumer_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"mortgage_consumer_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"mortgage_consumer_campaign.summary_label": "Zip Code(s)",
		"mortgage_consumer_campaign.billing_zip": "Billing Zip Code",
		"mortgage_referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"insurance_consumer_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the zip code you entered is not supported.",
		"insurance_consumer_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"insurance_consumer_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"insurance_consumer_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"insurance_consumer_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"insurance_consumer_campaign.city_state_placeholder": "e.g. Durham, NC",
		"insurance_consumer_campaign.city_state_label": "Your city, state",
		"insurance_consumer_campaign.notification_emails.zip_text": "Zip Code",
		"insurance_consumer_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"insurance_consumer_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"insurance_consumer_campaign.summary_label": "Zip Code(s)",
		"insurance_consumer_campaign.billing_zip": "Billing Zip Code",
		"insurance_referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"realtor_referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"real_estate_broker_recruiting_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"recruiting_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"wealth_management_referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"wealth_management_consumer_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the zip code you entered is not supported.",
		"wealth_management_consumer_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"wealth_management_consumer_campaign.zip_codes_lookup.error.missing": "You must have at least one zip code selected.",
		"wealth_management_consumer_campaign.zip_codes_spots.error.missing": "You must select at least one available zip code spot.",
		"wealth_management_consumer_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other zip codes.",
		"wealth_management_consumer_campaign.city_state_placeholder": "e.g. Durham, NC",
		"wealth_management_consumer_campaign.city_state_label": "Your city, state",
		"wealth_management_consumer_campaign.notification_emails.zip_text": "Zip Code",
		"wealth_management_consumer_campaign.notification_emails.upsell.other_zips_recommend": "Other Zip Codes You May Be Interested In",
		"wealth_management_consumer_campaign.notification_emails.upsell.claim_zip": "Claim This Zip",
		"wealth_management_consumer_campaign.summary_label": "Zip Code(s)",
		"wealth_management_consumer_campaign.billing_zip": "Billing Zip Code",
		"local_business_referral_campaign.unique_contacts.error.minimum_not_met": "You need to upload at least %{minimum_contacts} to get started.",
		"anx_web_and_mobile.custom300x250_image.error.required": "You must upload a custom Web Ad (300 x 250 pixels).",
		"anx_web.custom300x250_image.error.required": "You must upload a custom Web Ad (300 x 250 pixels).",
		"anx_mobile.custom320x50_image.error.required": "You must upload a custom Mobile Ad (320 x 50 pixels).",
		"anx_crm.custom300x250_image.error.required": "You must upload a custom Web Ad (300 x 250 pixels).",
		"facebook.custom600x314_image.error.required": "You must upload a custom Facebook Image (600 x 314 pixels).",
		"facebook.custom1200x628_image.error.required": "You must upload a custom Facebook Image (1200 x 628 pixels) or an mp4 video file.",
		"facebook_crm.custom600x314_image.error.required": "You must upload a custom Facebook Image (600 x 314 pixels).",
		"facebook_crm.custom1200x628_image.error.required": "You must upload a custom Facebook Image (1200 x 628 pixels) or an mp4 video file.",
		"activerecord.attributes.real_estate_campaign.display_ad_biafra_headline_text": "Headline",
		"activerecord.attributes.real_estate_campaign.display_ad_barry_headline_text": "Headline",
		"activerecord.attributes.real_estate_campaign.display_ad_dean_headline_text": "Headline",
		"activerecord.attributes.real_estate_campaign.display_ad_biafra_location_text": "Location",
		"activerecord.attributes.real_estate_campaign.display_ad_agent_text": "Agent",
		"activerecord.attributes.real_estate_campaign.display_ad_broker_text": "Broker",
		"activerecord.attributes.real_estate_campaign.display_ad_phone_text": "Phone",
		"homepage.check_zip.header": "%{ad_or_ads} by zip code",
		"homepage.check_zip.description": "Create awareness with prospects in your zip codes.",
		"homepage.check_zip.button_text": "Check zip availability",
		"homepage.check_zip.hero.header": "Claim Your Zip Codes",
		"profile_search.header.generic": "Select the closest match",
		"profile_search.header.tv": "Select the best match",
		"profile_search.subheader.generic": "We'll use this info to help build your ad, and you can edit it at any time",
		"profile_search.subheader.tv": "We use this info to complete your commercial",
		"profile_search.continue_ad.generic": "Continue with this ad",
		"profile_search.continue_ad.tv": "Finish campaign setup",
		"profile_search.copy_ad.generic": "Copy this ad",
		"profile_search.copy_ad.tv": "Copy this commercial",
		"profile_search.profile_missing.generic": "Skip this step and enter your details later",
		"product_labels.custom_real_estate": "Build a Custom Real Estate Campaign",
		"product_labels.custom_realtor": "Build a Custom Brand Campaign",
		"product_labels.geo.one": "Ad by zip code",
		"product_labels.geo.other": "Ads by zip codes",
		"product_labels.insurance_consumer.one": "Ad by zip code",
		"product_labels.insurance_consumer.other": "Ads by zip codes",
		"product_labels.insurance_referral.one": "Ad for referrals",
		"product_labels.insurance_referral.other": "Ads for referrals",
		"product_labels.mortgage_consumer.one": "Ad by zip code",
		"product_labels.mortgage_consumer.other": "Ads by zip codes",
		"product_labels.mortgage_referral.one": "Ad for referrals",
		"product_labels.mortgage_referral.other": "Ads for referrals",
		"product_labels.recruiting.one": "Recruiting Ad",
		"product_labels.recruiting.other": "Recruiting Ads",
		"product_labels.real_estate_broker_recruiting.one": "Recruiting Ad",
		"product_labels.real_estate_broker_recruiting.other": "Recruiting Ads",
		"product_labels.real_estate.one": "Ad for listing",
		"product_labels.real_estate.other": "Ads for listings",
		"product_labels.realtor_referral.one": "Ad for your sphere",
		"product_labels.realtor_referral.other": "Ads for your sphere",
		"product_labels.realtor.one": "Ad by zip code",
		"product_labels.realtor.other": "Ads by zip codes",
		"product_labels.with_postal_codes.one": "Ad by zip code",
		"product_labels.with_postal_codes.other": "Ads by zip codes",
		"product_labels.wealth_management_referral.one": "Ad for referrals",
		"product_labels.wealth_management_referral.other": "Ads for referrals",
		"product_labels.wealth_management_consumer.one": "Ad by zip code",
		"product_labels.wealth_management_consumer.other": "Ads by zip codes",
		"product_labels.wealth_management_retargeting.one": "Ad for retargeting",
		"product_labels.wealth_management_retargeting.other": "Ads for retargeting",
		"product_labels.local_business_referral.one": "Custom Audience ad",
		"product_labels.local_business_referral.other": "Custom Audience ads",
		"product_labels.retargeting.one": "Ad for retargeting",
		"product_labels.retargeting.other": "Ads for retargeting",
		"product_labels.referral.one": "Ad for referrals",
		"product_labels.referral.other": "Ads for referrals",
		"product_labels.tv.one": "Streaming TV Commercial",
		"product_labels.tv.other": "Streaming TV Commercials",
		"product_labels.tv_sphere.one": "Streaming TV Commercial for your sphere",
		"product_labels.tv_sphere.other": "Streaming TV Commercials for your sphere",
		"product_labels.tv_zip.one": "Streaming TV Commercial by Zip Code",
		"product_labels.tv_zip.other": "Streaming TV Commercials by Zip Code",
		"product_labels.brand_radius.one": "Ad for local areas",
		"product_labels.brand_radius.other": "Ads for local areas"
	},
	"en-au-geo": {
		"areas.header": "Click the postcode spots you want to claim.",
		"areas.subheader.generic": "Your ads will be shown to people in these postcodes when they're online.",
		"areas.enter_zip.label": "Not seeing your favorite postcode?",
		"areas.enter_zip.default_text": "Enter postcode",
		"areas.faq.what_sites": "We’ll show your ads on Facebook’s News Feed, popular mobile apps, and top websites like CNN, Yahoo, and many more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postcode?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential customers in each postcode, we restrict the number of advertisers in each postcode.",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postcode?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postcodes?",
		"areas.zip_column_header": "Postcodes",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"results.faq.who_sees": "Your ad will be shown to people located in your selected postcode when they browse Facebook and sites across the web."
	},
	"en-au-geo-geo": {
		"placeholder": ""
	},
	"en-au-real_estate": {
		"concerns.campaign_with_brand_ad_module.default_header": "Your Real Estate Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your Local Real Estate Expert",
		"areas.subheader.generic": "For each spot, your ad will be shown 10,000 times each month to people who live in these postcodes.",
		"areas.faq.how_many_spots.question": "How many total spots are there in each postcode?",
		"areas.faq.how_many_spots.answer.separate_types": "Each postcode is limited to %{num_spots_web} spots for Web ads and %{num_spots_mobile} spots for Mobile ads.",
		"areas.faq.how_many_spots.answer.combined_types": "Each postcode is limited to %{num_spots_combined} spots.",
		"areas.faq.what_sites": "Your ad will be featured on the entire Web, including sites like Yahoo, CNN and hundreds more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postcode?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential buyers and sellers in each postcode, we restrict the number of agents that can advertise in that location.",
		"areas.faq.facebook_targeting.question": "Who will see my ads?",
		"areas.faq.facebook_targeting.answer": "Web and mobile app ads target potential buyers and sellers who live in the zip codes that you are targeting. Facebook targets ads to potential buyers and sellers in a 15-mile radius around the center of your chosen zip code.\n",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postcode?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postcodes?",
		"areas.faq.why_different_prices.question": "Why do postcodes have different prices?",
		"areas.faq.why_different_prices.answer": "Spot prices are determined by the median property value in the postcode.",
		"areas.header": "Click the postcode spots you want to claim.",
		"areas.zip_column_header": "Postcode",
		"areas.enter_zip.label": "Not seeing your favorite postcode?",
		"areas.enter_zip.default_text": "Enter postcode"
	},
	"en-au-real_estate-realtor": {
		"results.faq.who_sees": "Potential buyers and sellers who live in the postcodes that you are targeting.",
		"landing_page.check_zip_header": "Check Postcodes",
		"landing_page.cta_button": "Check Postcode Availability",
		"landing_page.sample_screens.local_audience.zip_code_simple_pricing": "Target postcodes with simple pricing.",
		"landing_page.pricing_table.description": "per month / postcode spot"
	},
	"en-au-real_estate-realtor_referral": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their sphere",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx to stay top-of-mind with my sphere and thought you might be interested in it.\n\n%{root_url}sphere/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them a free trial invite."
	},
	"en-au-real_estate-real_estate": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "listing ads",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx for listings and thought you might be interested in it.\n\n%{root_url}real_estate/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite.",
		"results.faq.who_sees": "Potential buyers who are looking for a home in the same area as %{listing}."
	},
	"en-au-real_estate-real_estate_broker_recruiting": {
		"place_holder": ""
	},
	"en-au": {
		"zip_code.one": "postcode",
		"zip_code.other": "postcodes",
		"zip.one": "postcode",
		"zip.other": "postcodes",
		"concerns.campaign_with_brand_ad_module.default_phone": "5555 555 555",
		"areas_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postcode you entered is not supported.",
		"areas_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"areas_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"areas_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"areas_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"areas_campaign.city_state_placeholder": "e.g. Melbourne, Victoria",
		"areas_campaign.notification_emails.zip_text": "Postcode",
		"areas_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"areas_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"areas_campaign.summary_label": "Postcode(s)",
		"areas_campaign.billing_zip": "Billing Postcode",
		"realtor_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postcode you entered is not supported.",
		"realtor_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"realtor_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"realtor_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"realtor_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"realtor_campaign.city_state_placeholder": "e.g. Melbourne, Victoria",
		"realtor_campaign.notification_emails.zip_text": "Postcode",
		"realtor_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"realtor_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"realtor_campaign.summary_label": "Postcode(s)",
		"realtor_campaign.billing_zip": "Billing Postcode",
		"digital_out_of_home_zip_campaign.campaign_postal_code_dto.error.invalid_code": "No locations available in this zip code.",
		"digital_out_of_home_zip_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"digital_out_of_home_zip_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"digital_out_of_home_zip_campaign.city_state_placeholder": "e.g. Melbourne, Victoria",
		"digital_out_of_home_zip_campaign.notification_emails.zip_text": "Postcode",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"digital_out_of_home_zip_campaign.summary_label": "Postcode(s)",
		"digital_out_of_home_zip_campaign.billing_zip": "Billing Postcode",
		"homepage.check_zip.header": "%{ad_or_ads} by postcode",
		"homepage.check_zip.description": "Create awareness with prospects in your postcodes.",
		"homepage.check_zip.button_text": "Check availability",
		"homepage.check_zip.hero.header": "Claim Your Postcodes",
		"campaign_postal_codes.edit_collection.zip_code_placeholder": "e.g. 2601",
		"product_labels.custom_real_estate": "Build a Custom Real Estate Campaign",
		"product_labels.custom_realtor": "Build a Custom Brand Campaign",
		"product_labels.geo.one": "Ad by postcode",
		"product_labels.geo.other": "Ads by postcodes",
		"product_labels.insurance_consumer.one": "Ad by postcode",
		"product_labels.insurance_consumer.other": "Ads by postcodes",
		"product_labels.insurance_referral.one": "Ad for referrals",
		"product_labels.insurance_referral.other": "Ads for referrals",
		"product_labels.mortgage_consumer.one": "Ad by postcode",
		"product_labels.mortgage_consumer.other": "Ads by postcodes",
		"product_labels.mortgage_referral.one": "Ad for referrals",
		"product_labels.mortgage_referral.other": "Ads for referrals",
		"product_labels.recruiting.one": "Recruiting Ad",
		"product_labels.recruiting.other": "Recruiting Ads",
		"product_labels.real_estate_broker_recruiting.one": "Recruiting Ad",
		"product_labels.real_estate_broker_recruiting.other": "Recruiting Ads",
		"product_labels.real_estate.one": "Ad for listing",
		"product_labels.real_estate.other": "Ads for listings",
		"product_labels.realtor_referral.one": "Ad for your sphere",
		"product_labels.realtor_referral.other": "Ads for your sphere",
		"product_labels.realtor.one": "Ad by postcode",
		"product_labels.realtor.other": "Ads by postcodes",
		"product_labels.with_postal_codes.one": "Ad by postcode",
		"product_labels.with_postal_codes.other": "Ads by postcodes",
		"product_labels.wealth_management_referral.one": "Ad for referrals",
		"product_labels.wealth_management_referral.other": "Ads for referrals",
		"product_labels.wealth_management_consumer.one": "Ad by postcode",
		"product_labels.wealth_management_consumer.other": "Ads by postcodes",
		"product_labels.wealth_management_retargeting.one": "Ad for retargeting",
		"product_labels.wealth_management_retargeting.other": "Ads for retargeting",
		"product_labels.local_business_referral.one": "Custom Audience ad",
		"product_labels.local_business_referral.other": "Custom Audience ads",
		"product_labels.retargeting.one": "Ad for retargeting",
		"product_labels.retargeting.other": "Ads for retargeting",
		"product_labels.referral.one": "Ad for referrals",
		"product_labels.referral.other": "Ads for referrals",
		"product_labels.tv.one": "Streaming TV Commercial",
		"product_labels.tv.other": "Streaming TV Commercials",
		"product_labels.tv_sphere.one": "Streaming TV Commercial for your sphere",
		"product_labels.tv_sphere.other": "Streaming TV Commercials for your sphere",
		"product_labels.tv_zip.one": "Streaming TV Commercial by postcode",
		"product_labels.tv_zip.other": "Streaming TV Commercials by postcode",
		"product_labels.brand_radius.one": "Ad for local areas",
		"product_labels.brand_radius.other": "Ads for local areas"
	},
	"en-ca-real_estate-brand_radius": {
		"campaign.ad_noun_descriptor": "ad",
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Enter your tagline here",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-wealth_management-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your local financial pro",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-insurance-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "I can help find your perfect insurance plan.",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-mortgage-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your trusted source for refinancing and new mortgages.",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-generic-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-geo-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-local_business-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-ca-geo": {
		"areas.header": "Click the postal code spots you want to claim.",
		"areas.subheader.generic": "Your ads will be shown to people in these postal codes when they're online.",
		"areas.enter_zip.label": "Not seeing your favorite postal code?",
		"areas.enter_zip.default_text": "Enter postal code",
		"areas.faq.what_sites": "We'll show your ads on Facebook’s News Feed, popular mobile apps, and top websites like CNN, Yahoo, and many more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postal code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential customers in each postal code, we restrict the number of advertisers in each postal code.",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postal code?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postal codes?",
		"areas.zip_column_header": "Postal Codes",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"results.faq.who_sees": "Your ad will be shown to people located in your selected postal codes when they browse Facebook and sites across the web."
	},
	"en-ca-geo-geo": {
		"placeholder": ""
	},
	"en-ca-real_estate": {
		"concerns.campaign_with_brand_ad_module.default_header": "Your Real Estate Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your Local Real Estate Expert",
		"areas.subheader.generic": "For each spot, your ad will be shown 10,000 times each month to people who live in these postal codes.",
		"areas.faq.how_many_spots.question": "How many total spots are there in each postal code?",
		"areas.faq.how_many_spots.answer.separate_types": "Each postal code is limited to %{num_spots_web} spots for Web ads and %{num_spots_mobile} spots for Mobile ads.",
		"areas.faq.how_many_spots.answer.combined_types": "Each postal code is limited to %{num_spots_combined} spots.",
		"areas.faq.what_sites": "Your ad will be featured on the entire Web, including sites like Yahoo, CNN and hundreds more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postal code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential buyers and sellers in each postal code, we restrict the number of agents that can advertise in that location.",
		"areas.faq.facebook_targeting.question": "Who will see my ads?",
		"areas.faq.facebook_targeting.answer": "Web and mobile app ads target potential buyers and sellers who live in the zip codes that you are targeting. Facebook targets ads to potential buyers and sellers in a 15-mile radius around the center of your chosen zip code.\n",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postal code?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postal codes?",
		"areas.faq.why_different_prices.question": "Why do postal codes have different prices?",
		"areas.faq.why_different_prices.answer": "Spot prices are determined by the median property value in the postal code.",
		"areas.header": "Click the postal code spots you want to claim.",
		"areas.zip_column_header": "Postal Code",
		"areas.enter_zip.label": "Not seeing your favorite postal code?",
		"areas.enter_zip.default_text": "Enter postal code"
	},
	"en-ca-real_estate-realtor": {
		"results.faq.who_sees": "Potential buyers and sellers who live in the postal codes that you are targeting.",
		"landing_page.check_zip_header": "Check Postal Codes",
		"landing_page.cta_button": "Check Availability",
		"landing_page.sample_screens.local_audience.zip_code_simple_pricing": "Target postal codes with simple pricing.",
		"landing_page.pricing_table.generic": "",
		"landing_page.pricing_table.description": "per month / postal code spot"
	},
	"en-ca-real_estate-realtor_referral": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their sphere",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx to stay top-of-mind with my sphere and thought you might be interested in it.\n\n%{root_url}sphere/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them a free trial invite."
	},
	"en-ca-real_estate-real_estate": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "listing ads",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx for listings and thought you might be interested in it.\n\n%{root_url}real_estate/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite.",
		"results.faq.who_sees": "Potential buyers who are looking for a home in the same area as %{listing}."
	},
	"en-ca-real_estate-real_estate_broker_recruiting": {
		"place_holder": ""
	},
	"en-ca-generic-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here"
	},
	"en-ca-geo-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca-insurance-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca-local_business-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca-mortgage-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca-real_estate-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca-wealth_management-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-ca": {
		"zip_code.one": "postal code",
		"zip_code.other": "postal codes",
		"zip.one": "postal code",
		"zip.other": "postal codes",
		"areas_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postal code you entered is not supported.",
		"areas_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"areas_campaign.zip_codes_lookup.error.missing": "You must have at least one postal code selected.",
		"areas_campaign.zip_codes_spots.error.missing": "You must select at least one available postal code spot.",
		"areas_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postal codes.",
		"areas_campaign.city_state_placeholder": "e.g. Toronto, Ontario",
		"areas_campaign.city_state_label": "Your city, province",
		"areas_campaign.notification_emails.zip_text": "Postal Code",
		"areas_campaign.notification_emails.upsell.other_zips_recommend": "Other Postal Codes You May Be Interested In",
		"areas_campaign.notification_emails.upsell.claim_zip": "Claim This Postal Code",
		"areas_campaign.summary_label": "Postal Code(s)",
		"areas_campaign.billing_zip": "Billing Postal Code",
		"realtor_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postal code you entered is not supported.",
		"realtor_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"realtor_campaign.zip_codes_lookup.error.missing": "You must have at least one postal code selected.",
		"realtor_campaign.zip_codes_spots.error.missing": "You must select at least one available postal code spot.",
		"realtor_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postal codes.",
		"realtor_campaign.city_state_placeholder": "e.g. Toronto, Ontario",
		"realtor_campaign.city_state_label": "Your city, province",
		"realtor_campaign.notification_emails.zip_text": "Postal Code",
		"realtor_campaign.notification_emails.upsell.other_zips_recommend": "Other Postal Codes You May Be Interested In",
		"realtor_campaign.notification_emails.upsell.claim_zip": "Claim This Postal Code",
		"realtor_campaign.summary_label": "Postal Code(s)",
		"realtor_campaign.billing_zip": "Billing Postal Code",
		"digital_out_of_home_zip_campaign.campaign_postal_code_dto.error.invalid_code": "No locations available in this zip code.",
		"digital_out_of_home_zip_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"digital_out_of_home_zip_campaign.zip_codes_lookup.error.missing": "You must have at least one postal code selected.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.missing": "You must select at least one available postal code spot.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postal codes.",
		"digital_out_of_home_zip_campaign.city_state_placeholder": "e.g. Toronto, Ontario",
		"digital_out_of_home_zip_campaign.city_state_label": "Your city, province",
		"digital_out_of_home_zip_campaign.notification_emails.zip_text": "Postal Code",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.other_zips_recommend": "Other Postal Codes You May Be Interested In",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.claim_zip": "Claim This Postal Code",
		"digital_out_of_home_zip_campaign.summary_label": "Postal Code(s)",
		"digital_out_of_home_zip_campaign.billing_zip": "Billing Postal Code",
		"homepage.check_zip.header": "%{ad_or_ads} by postal code",
		"homepage.check_zip.description": "Create awareness with prospects in your postal codes.",
		"homepage.check_zip.button_text": "Check availability",
		"homepage.check_zip.hero.header": "Claim Your Postal Codes",
		"homepage.footer.disclaimer": "Any use of the term 'sales associate' or 'agent' shall be replaced with the term 'sales representative' in Canada.",
		"campaign_postal_codes.edit_collection.zip_code_placeholder": "e.g. K1R",
		"product_labels.custom_real_estate": "Build a Custom Real Estate Campaign",
		"product_labels.custom_realtor": "Build a Custom Brand Campaign",
		"product_labels.geo.one": "Ad by postal code",
		"product_labels.geo.other": "Ads by postal codes",
		"product_labels.insurance_consumer.one": "Ad by postal code",
		"product_labels.insurance_consumer.other": "Ads by postal codes",
		"product_labels.insurance_referral.one": "Ad for referrals",
		"product_labels.insurance_referral.other": "Ads for referrals",
		"product_labels.mortgage_consumer.one": "Ad by postal code",
		"product_labels.mortgage_consumer.other": "Ads by postal codes",
		"product_labels.mortgage_referral.one": "Ad for referrals",
		"product_labels.mortgage_referral.other": "Ads for referrals",
		"product_labels.recruiting.one": "Recruiting Ad",
		"product_labels.recruiting.other": "Recruiting Ads",
		"product_labels.real_estate_broker_recruiting.one": "Recruiting Ad",
		"product_labels.real_estate_broker_recruiting.other": "Recruiting Ads",
		"product_labels.real_estate.one": "Ad for listing",
		"product_labels.real_estate.other": "Ads for listings",
		"product_labels.realtor_referral.one": "Ad for your sphere",
		"product_labels.realtor_referral.other": "Ads for your sphere",
		"product_labels.realtor.one": "Ad by postal code",
		"product_labels.realtor.other": "Ads by postal codes",
		"product_labels.with_postal_codes.one": "Ad by postal code",
		"product_labels.with_postal_codes.other": "Ads by postal codes",
		"product_labels.wealth_management_referral.one": "Ad for referrals",
		"product_labels.wealth_management_referral.other": "Ads for referrals",
		"product_labels.wealth_management_consumer.one": "Ad by postal code",
		"product_labels.wealth_management_consumer.other": "Ads by postal codes",
		"product_labels.wealth_management_retargeting.one": "Ad for retargeting",
		"product_labels.wealth_management_retargeting.other": "Ads for retargeting",
		"product_labels.local_business_referral.one": "Custom Audience ad",
		"product_labels.local_business_referral.other": "Custom Audience ads",
		"product_labels.retargeting.one": "Ad for retargeting",
		"product_labels.retargeting.other": "Ads for retargeting",
		"product_labels.referral.one": "Ad for referrals",
		"product_labels.referral.other": "Ads for referrals",
		"product_labels.tv.one": "Streaming TV Commercial",
		"product_labels.tv.other": "Streaming TV Commercials",
		"product_labels.tv_sphere.one": "Streaming TV Commercial for your sphere",
		"product_labels.tv_sphere.other": "Streaming TV Commercials for your sphere",
		"product_labels.tv_zip.one": "Streaming TV Commercial by postal code",
		"product_labels.tv_zip.other": "Streaming TV Commercials by postal code",
		"product_labels.brand_radius.one": "Ad for local areas",
		"product_labels.brand_radius.other": "Ads for local areas"
	},
	"en-gb-geo": {
		"areas.header": "Click the postcode spots you want to claim.",
		"areas.subheader.generic": "Your ads will be shown to people in these postcodes when they're online.",
		"areas.enter_zip.label": "Not seeing your favorite postcode?",
		"areas.enter_zip.default_text": "Enter postcode",
		"areas.faq.what_sites": "We’ll show your ads on Facebook’s News Feed, popular mobile apps, and top websites like CNN, Yahoo, and many more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postcode?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential customers in each postcode, we restrict the number of advertisers in each postcode.",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postcode?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postcodes?",
		"areas.zip_column_header": "Postcodes",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"results.faq.who_sees": "Your ad will be shown to people located in your selected postcodes when they browse Facebook and sites across the web."
	},
	"en-gb-geo-geo": {
		"placeholder": ""
	},
	"en-gb-real_estate": {
		"concerns.campaign_with_brand_ad_module.default_header": "Your Real Estate Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your Local Real Estate Expert",
		"areas.subheader.generic": "For each spot, your ad will be shown 10,000 times each month to people who live in these postcodes.",
		"areas.faq.how_many_spots.question": "How many total spots are there in each postcode?",
		"areas.faq.how_many_spots.answer.separate_types": "Each postcode is limited to %{num_spots_web} spots for Web ads and %{num_spots_mobile} spots for Mobile ads.",
		"areas.faq.how_many_spots.answer.combined_types": "Each postcode is limited to %{num_spots_combined} spots.",
		"areas.faq.what_sites": "Your ad will be featured on the entire Web, including sites like Yahoo, CNN and hundreds more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each postcode?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential buyers and sellers in each postcode, we restrict the number of agents that can advertise in that location.",
		"areas.faq.facebook_targeting.question": "Who will see my ads?",
		"areas.faq.facebook_targeting.answer": "Web and mobile app ads target potential buyers and sellers who live in the zip codes that you are targeting. Facebook targets ads to potential buyers and sellers in a 15-mile radius around the center of your chosen zip code.\n",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a postcode?",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the ads to my postcodes?",
		"areas.faq.why_different_prices.question": "Why do postcodes have different prices?",
		"areas.faq.why_different_prices.answer": "Spot prices are determined by the median property value in the postcode.",
		"areas.header": "Click the postcode spots you want to claim.",
		"areas.zip_column_header": "Postcode",
		"areas.enter_zip.label": "Not seeing your favorite postcode?",
		"areas.enter_zip.default_text": "Enter postcode"
	},
	"en-gb-real_estate-realtor": {
		"results.faq.who_sees": "Potential buyers and sellers who live in the postcodes that you are targeting.",
		"landing_page.check_zip_header": "Check Postcodes",
		"landing_page.cta_button": "Check Postcode Availability",
		"landing_page.sample_screens.local_audience.zip_code_simple_pricing": "Target postcodes with simple pricing.",
		"landing_page.pricing_table.description": "per month / postcode spot"
	},
	"en-gb-real_estate-realtor_referral": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their sphere",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx to stay top-of-mind with my sphere and thought you might be interested in it.\n\n%{root_url}sphere/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them a free trial invite."
	},
	"en-gb-real_estate-real_estate": {
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "listing ads",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx for listings and thought you might be interested in it.\n\n%{root_url}real_estate/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite.",
		"results.faq.who_sees": "Potential buyers who are looking for a home in the same area as %{listing}."
	},
	"en-gb-real_estate-real_estate_broker_recruiting": {
		"place_holder": ""
	},
	"en-gb": {
		"zip_code.one": "postcode",
		"zip_code.other": "postcodes",
		"zip.one": "postcode",
		"zip.other": "postcodes",
		"concerns.campaign_with_brand_ad_module.default_phone": "555 5555 5555",
		"areas_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postcode you entered is not supported.",
		"areas_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"areas_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"areas_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"areas_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"areas_campaign.city_state_placeholder": "e.g. London, England",
		"areas_campaign.city_state_label": "Your city, county",
		"areas_campaign.notification_emails.zip_text": "Postcode",
		"areas_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"areas_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"areas_campaign.summary_label": "Postcode(s)",
		"areas_campaign.billing_zip": "Billing Postcode",
		"realtor_campaign.campaign_postal_code_dto.error.invalid_code": "Sorry, the postcode you entered is not supported.",
		"realtor_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"realtor_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"realtor_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"realtor_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"realtor_campaign.city_state_placeholder": "e.g. London, England",
		"realtor_campaign.city_state_label": "Your city, county",
		"realtor_campaign.notification_emails.zip_text": "Postcode",
		"realtor_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"realtor_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"realtor_campaign.summary_label": "Postcode(s)",
		"realtor_campaign.billing_zip": "Billing Postcode",
		"digital_out_of_home_zip_campaign.campaign_postal_code_dto.error.invalid_code": "No locations available in this zip code.",
		"digital_out_of_home_zip_campaign.cities_lookup.error.missing": "You must have at least one city selected.",
		"digital_out_of_home_zip_campaign.zip_codes_lookup.error.missing": "You must have at least one postcode selected.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.missing": "You must select at least one available postcode spot.",
		"digital_out_of_home_zip_campaign.zip_codes_spots.error.sold_out": "All spots are sold out, please use other postcodes.",
		"digital_out_of_home_zip_campaign.city_state_placeholder": "e.g. London, England",
		"digital_out_of_home_zip_campaign.city_state_label": "Your city, county",
		"digital_out_of_home_zip_campaign.notification_emails.zip_text": "Postcode",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.other_zips_recommend": "Other Postcodes You May Be Interested In",
		"digital_out_of_home_zip_campaign.notification_emails.upsell.claim_zip": "Claim This Postcode",
		"digital_out_of_home_zip_campaign.summary_label": "Postcode(s)",
		"digital_out_of_home_zip_campaign.billing_zip": "Billing Postcode",
		"homepage.check_zip.header": "%{ad_or_ads} by postcode",
		"homepage.check_zip.description": "Create awareness with prospects in your postcodes.",
		"homepage.check_zip.button_text": "Check availability",
		"homepage.check_zip.hero.header": "Claim Your Postcodes",
		"campaign_postal_codes.edit_collection.zip_code_placeholder": "e.g. NE1"
	},
	"en-us-real_estate-brand_radius": {
		"campaign.ad_noun_descriptor": "ad",
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Enter your tagline here",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-wealth_management-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your local financial pro",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-insurance-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "I can help find your perfect insurance plan.",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-mortgage-brand_radius": {
		"campaign.errors.campaign_custom_locations.invalid_radius": "Please select a different size radius from the available options.\n",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you've selected is too small to run a Radius campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.\n",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Radius campaigns are currently not available in the selected geography.\n",
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your trusted source for refinancing and new mortgages.",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-generic-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-geo-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-local_business-brand_radius": {
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the geographic area that you have selected.\n"
	},
	"en-us-custom_real_estate-custom_real_estate": {
		"areas": null,
		"concerns.campaign_with_brand_ad_module.default_header": "",
		"results": null
	},
	"en-us-custom_realtor-custom_realtor": {
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here"
	},
	"en-us-generic-tv": {
		"launch.preview_ad_label.generic": "Commercial Preview",
		"campaign.ad_noun_descriptor": "commercial",
		"campaign.renewal_modal_headline": "Stay in the spotlight, keep your TV commercial running!",
		"campaign.errors.campaign_custom_locations.invalid": "The target area you’ve selected is too small to run a TV campaign. Select a larger target area by increasing your radius or dropping more pins to proceed.",
		"campaign.errors.campaign_custom_locations.unavailable": "Please select a target area in the United States. Streaming TV campaigns are currently not available in the selected geography.",
		"campaign.stats.how_many_prompt": "How many times has your commercial been %{past_participle}?",
		"campaign.stats.tv_prompt": "Your Streaming TV Commercial Performance",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here"
	},
	"en-us-geo": {
		"areas.subheader.generic": "Your ads will be shown to people in these zip codes when they're online.",
		"areas.faq.what_sites": "We’ll show your ads on Facebook’s News Feed, popular mobile apps, and top websites like CNN, Yahoo, and many more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each zip code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential customers in each zip code, we restrict the number of advertisers in each zip code.",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"results.faq.who_sees": "Your ad will be shown to people located in your selected zip codes when they browse Facebook and sites across the web."
	},
	"en-us-geo-geo": {
		"placeholder": ""
	},
	"en-us-insurance": {
		"concerns.campaign_with_brand_ad_module.default_header": "Your Insurance Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "I can help find your perfect insurance plan.",
		"concerns.campaign_with_brand_ad_module.default_broker": "Enter your license and\ncontact info here",
		"product_page.header.generic": "Brilliantly simple digital advertising for life insurance advisors.",
		"product_page.header.ab_alternate": "Grow your insurance business with ads on Facebook and the web.",
		"product_page.header.search_by_phone": "Brilliantly simple online</br>advertising for insurance advisors.",
		"product_page.header.referral": "Insurance agents:</br>Increase your referral business.",
		"product_page.subheader.generic": "Grow your insurance business with digital ads on Facebook, the web, and mobile apps",
		"product_page.subheader.ab_alternate": "Brilliantly simple digital advertising designed specifically for insurance advisors",
		"product_page.subheader.search_by_phone": "Advertise your business on Facebook and the web",
		"product_page.subheader.referral": "Stay top-of-mind with past clients and referral partners</br>with advertising on Facebook and the Web.",
		"product_page.search_header.find_my_business": "Look up your business by phone number.",
		"product_page.search_header.see_my_ad": "Enter your phone number to see the ad we've created for you.",
		"product_page.search_header.referral_subheader": "Set up your ad campaign in minutes.  Pricing starts as low as $99/month.",
		"product_page.search_button.find_my_business": "Find my business",
		"product_page.search_button.see_my_ad": "See my ad",
		"results.faq.who_sees": "Consumers who are looking for insurance and live in the zip codes that you are targeting.",
		"areas.subheader.generic": "Ads are shown to people who are in your zip codes and in the market for life insurance.",
		"areas.subheader.variant_b": "Ads are shown to people who are in your zip codes and shopping for insurance.",
		"areas.faq.what_sites": "Your ad will be featured on the entire Web, including sites like Yahoo, CNN and hundreds more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each zip code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential insurance customers in each zip code, we restrict the number of insurance agents that can advertise in that location.",
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their insurance business",
		"facebook_page_feed_update_email.referral_invite.body_text": "\"Hi! I'm trying out some advertising from Adwerx to grow my insurance business and thought you might be interested in it.\\n\\n%{root_url}insurance\"",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite."
	},
	"en-us-insurance-insurance_consumer": {
		"placeholder": ""
	},
	"en-us-insurance-insurance_referral": {
		"results.faq.who_sees": "Your ads are targeted specifically to just the people that you uploaded and matched to our system."
	},
	"en-us-local_business-local_business_referral": {
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here",
		"results.faq.who_sees": "Your ads are targeted specifically to just the people that you uploaded and matched to our system."
	},
	"en-us-mortgage": {
		"layout.ui_controls.fields.header": "NMLS #",
		"concerns.campaign_with_brand_ad_module.default_header": "NMLS #",
		"concerns.campaign_with_brand_ad_module.default_broker": "Enter your license and\ncontact info here",
		"product_page.header.search_by_phone": "Brilliantly simple online</br>advertising for mortgage lenders.",
		"product_page.subheader.search_by_phone": "Advertise your business on Facebook and the web.",
		"product_page.search_header.find_my_business": "Look up your business by phone number.",
		"product_page.search_header.see_my_ad": "Enter your phone number to see the ad we've created for you.",
		"product_page.search_header.referral_subheader": "Set up your ad campaign in minutes.  Pricing starts as low as $99/month.",
		"product_page.search_button.find_my_business": "Find my business",
		"product_page.search_button.see_my_ad": "See my ad",
		"results.faq.who_sees": "Consumers who are looking to refinance their existing mortgage or open a new mortgage and live in the zip codes that you are targeting.",
		"areas.subheader.generic": "Ads are shown to people who are in your zip codes and looking to refinance or obtain a new mortgage.",
		"areas.faq.what_sites": "We’ll show your ads on Facebook’s News Feed, popular mobile apps, and top websites like CNN, Yahoo, and many more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each zip code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential buyers and sellers in each zip code, we restrict the number of agents that can advertise in that location.",
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their mortgage business",
		"facebook_page_feed_update_email.referral_invite.body_text": "\"Hi! I'm trying out some advertising from Adwerx to grow my mortgage business and thought you might be interested in it.\\n\\n%{root_url}mortgage\"",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite."
	},
	"en-us-mortgage-mortgage_consumer": {
		"product_page.header.generic": "Brilliantly simple digital advertising for mortgage lenders.",
		"product_page.header.supremelending": "Supreme Lending loan officers: <br />Promote your brand with digital ads.",
		"product_page.header.afn": "American Financial Network, Inc. loan officers: <br />Promote your brand with digital ads.",
		"product_page.subheader.generic": "Grow your mortgage business by advertising on Facebook, the web, and mobile apps.",
		"product_page.subheader.supremelending": "Advertise your business on Facebook and the web with digital ads <br />that target prospects.",
		"product_page.advertiser_profile_search.cta.generic": "Check Availability",
		"product_page.advertiser_profile_search.cta.supremelending": "Check my zip availability",
		"product_page.advertiser_profile_search.cta.afn": "Check my zip availability",
		"product_page.large_product_panel.header.generic": "Advertise direct to consumers (and agents!)",
		"product_page.large_product_panel.header.supremelending": "Advertise direct to consumers",
		"product_page.large_product_panel.header.afn": "Advertise direct to consumers",
		"product_page.large_product_panel.list_items.generic": "The ads automatically target local borrowers looking to refinance or obtain a new mortgage, and local real estate agents who can refer business to you. | No setup fees. | Pricing starts at $%{monthly_price}/month.",
		"product_page.large_product_panel.list_items.supremelending": "Grow your business with ads on Facebook and the web. | The ads automatically target local borrowers looking to refinance or obtain a new mortgage, and local real estate agents who can refer business to you. | No setup fees.",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your trusted source for refinancing and new mortgages.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each zip code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential mortgage and refinance customers in each zip code, we restrict the number of lenders that can advertise in that location."
	},
	"en-us-mortgage-mortgage_referral": {
		"product_page.header.generic": "Grow Your Business<br />By Engaging Your Referral Network",
		"product_page.header.supremelending": "Supreme Lending loan officers: <br />Increase your referral business",
		"product_page.subheader.generic": "Build better connections and stay top-of-mind with everyone in your database.",
		"product_page.subheader.supremelending": "Build better connections and stay top-of-mind with everyone in your database.",
		"product_page.advertiser_profile_search.cta.generic": "See my ad",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your trusted source for refinancing and new mortgages.",
		"results.faq.who_sees": "Your ads are targeted specifically to just the people that you uploaded and matched to our system."
	},
	"en-us": {
		"nrt_survey_email.subject": "Would you recommend Adwerx?",
		"nrt_survey_email.intro": "Coldwell Banker/NRT develops products for affiliated agents based on your feedback. We hope you can take a moment to answer the question below.",
		"nrt_survey_email.prompt_text": "How likely are you to recommend Adwerx to other real estate professionals?",
		"nrt_survey_email.senders_name": "Coldwell Banker",
		"nrt_survey_email.reply_to_email": "cbmarketing@nrtllc.com",
		"real_estate-retargeting.results.faq.who_sees": "Your ads are targeted to your website visitors.",
		"real_estate-retargeting.concerns.campaign_with_brand_ad_module.default_phone": "(555) 555-5555",
		"real_estate-retargeting.concerns.campaign_with_brand_ad_module.default_header": "Your Real Estate Pro",
		"real_estate-retargeting.concerns.campaign_with_brand_ad_module.default_tagline": "Your Local Real Estate Expert",
		"placeholder": "",
		"campaign_postal_codes.edit_collection.price_header": "Prices reflect cost per month",
		"campaign_postal_codes.edit_collection.zip_code_placeholder": "e.g. 27701",
		"areas.subheader.generic": "For each spot, your ad will be shown 10,000 times each month to people who live in these zip codes.",
		"areas.faq.how_many_spots.question": "How many total spots are there in each zip code?",
		"areas.faq.how_many_spots.answer.separate_types": "Each zip code is limited to %{num_spots_web} spots for Web ads and %{num_spots_mobile} spots for Mobile ads.",
		"areas.faq.how_many_spots.answer.combined_types": "Each zip code is limited to %{num_spots_combined} spots.",
		"areas.faq.what_sites": "Your ad will be featured on the entire Web, including sites like Yahoo, CNN and hundreds more.",
		"areas.faq.why_limit.question": "Why are there a limited number of spots for each zip code?",
		"areas.faq.why_limit.answer": "Because there are a limited number of potential buyers and sellers in each zip code, we restrict the number of agents that can advertise in that location.",
		"areas.faq.facebook_targeting.question": "Who will see my ads?",
		"areas.faq.facebook_targeting.answer": "Web and mobile app ads target potential buyers and sellers who live in the zip codes that you are targeting. Facebook targets ads to potential buyers and sellers in a 15-mile radius around the center of your chosen zip code.\n",
		"areas.faq.exclusive_access.question": "Can I get exclusive access to a zip code?",
		"areas.faq.exclusive_access.answer.digital_out_of_home_zip": "Yes, just select all of the available spots in the zip code to get exclusivity.",
		"areas.faq.target_ads_to_my_zip.question": "How do you target the %{ad_or_commercial} to my zip codes?",
		"areas.faq.why_different_prices.question": "Why do zip codes have different prices?",
		"areas.faq.why_different_prices.answer": "Spot prices are determined by the median property value in the zip code.",
		"areas.faq.after_purchase.question": "What should I expect after purchase?",
		"areas.faq.after_purchase.answer": "Increased brand awareness. The goal with brand awareness campaigns is to let the people in your community know who you are and what you do!",
		"areas.faq.visibility.question": "Where will my ads be shown?",
		"areas.faq.visibility.answer.digital_out_of_home_zip": "Your ads will be on digital screens in malls, gyms, restaurants, gas stations, billboards and more in the zip codes you select.",
		"areas.faq.duration.question": "How long is my ad shown?",
		"areas.faq.duration.answer.digital_out_of_home_zip": "Each time your ad is viewed, it appears on the digital screen for 15 seconds.  Your ad will only be shown when a venue is open and there are people to view the ad.",
		"areas.faq.edit_ad_after_purchase.question": "Can I edit my ad after purchase?",
		"areas.faq.edit_ad_after_purchase.answer": "Yes, ads can be edited after they go live in your Adwerx Account.",
		"areas.faq.dooh_venue_category.question": "Can I choose the specific venue categories I want to target?",
		"areas.faq.dooh_venue_category.answer": "To get the most exposure and brand awareness it is important for your ad to show on any of the available venue categories.  This will allow you to reach the maximize number of potential customers and build a strong local brand.",
		"areas.header": "Click the zip code spots you want to claim.",
		"areas.zip_column_header": "Zip Code",
		"areas.enter_zip.label": "Not seeing your favorite zip?",
		"areas.enter_zip.default_text": "Enter zip",
		"admin.partner_overview_permissions.create.success": "%{name} has been given access to the Partner Dashboard",
		"admin.partner_overview_permissions.create.error": "Something went wrong and %{name} does not have access to the Partner Dashboard",
		"admin.partner_overview_permissions.revoke.success": "%{name} no longer has access to the Partner Dashboard",
		"admin.partner_overview_permissions.revoke.error": "Something went wrong and %{name} still has access to the Partner Dashboard",
		"api_v2.campaign.errors.seller_info": "is not a valid choice. Please specify 'entered', 'absent', or 'all'",
		"api_v2.campaign.errors.ad_type": "is not a valid choice. Please specify a valid campaign short type",
		"api_v2.campaign.errors.status": "is not a valid choice. Please specify 'live', 'all', or a valid status",
		"api_v2.campaign.errors.index_ad_type": "is not a valid choice. Please specify 'all', or a valid campaign short type",
		"oauth_with_jwt.errors.redirect_url": "missing redirect url. Please contact Adwerx for assistance.",
		"oauth_with_jwt.errors.sso_settings": "is not a valid setting. Please contact Adwerx for assistance.",
		"oauth_with_jwt.errors.user": "is not an authorized user.  Please check your credentials and try again.",
		"product_labels.custom_real_estate": "Build a Custom Real Estate Campaign",
		"product_labels.custom_realtor": "Build a Custom Brand Campaign",
		"product_labels.geo.one": "Ad by zip code",
		"product_labels.geo.other": "Ads by zip codes",
		"product_labels.insurance_consumer.one": "Ad by zip code",
		"product_labels.insurance_consumer.other": "Ads by zip codes",
		"product_labels.insurance_referral.one": "Ad for referrals",
		"product_labels.insurance_referral.other": "Ads for referrals",
		"product_labels.mortgage_consumer.one": "Ad by zip code",
		"product_labels.mortgage_consumer.other": "Ads by zip codes",
		"product_labels.mortgage_referral.one": "Ad for referrals",
		"product_labels.mortgage_referral.other": "Ads for referrals",
		"product_labels.recruiting.one": "Recruiting Ad",
		"product_labels.recruiting.other": "Recruiting Ads",
		"product_labels.real_estate_broker_recruiting.one": "Recruiting Ad",
		"product_labels.real_estate_broker_recruiting.other": "Recruiting Ads",
		"product_labels.real_estate.one": "Ad for listing",
		"product_labels.real_estate.other": "Ads for listings",
		"product_labels.realtor_referral.one": "Ad for your sphere",
		"product_labels.realtor_referral.other": "Ads for your sphere",
		"product_labels.realtor.one": "Ad by zip code",
		"product_labels.realtor.other": "Ads by zip codes",
		"product_labels.with_postal_codes.one": "Ad by zip code",
		"product_labels.with_postal_codes.other": "Ads by zip codes",
		"product_labels.wealth_management_referral.one": "Ad for referrals",
		"product_labels.wealth_management_referral.other": "Ads for referrals",
		"product_labels.wealth_management_consumer.one": "Ad by zip code",
		"product_labels.wealth_management_consumer.other": "Ads by zip codes",
		"product_labels.wealth_management_retargeting.one": "Ad for retargeting",
		"product_labels.wealth_management_retargeting.other": "Ads for retargeting",
		"product_labels.local_business_referral.one": "Custom Audience ad",
		"product_labels.local_business_referral.other": "Custom Audience ads",
		"product_labels.retargeting.one": "Ad for retargeting",
		"product_labels.retargeting.other": "Ads for retargeting",
		"product_labels.referral.one": "Ad for referrals",
		"product_labels.referral.other": "Ads for referrals",
		"product_labels.tv.one": "Streaming TV Commercial",
		"product_labels.tv.other": "Streaming TV Commercials",
		"product_labels.tv_sphere.one": "Streaming TV Commercial for your sphere",
		"product_labels.tv_sphere.other": "Streaming TV Commercials for your sphere",
		"product_labels.tv_zip.one": "Streaming TV Commercial by Zip Code",
		"product_labels.tv_zip.other": "Streaming TV Commercials by Zip Code",
		"product_labels.brand_radius.one": "Ad for local areas",
		"product_labels.brand_radius.other": "Ads for local areas"
	},
	"en-us-real_estate-digital_out_of_home_zip": {
		"results.stats.how_many_prompt": "Your Digital Away From Home Ad Performance",
		"results.faq.who_sees": "Your ads are targeted to individuals who are physically located in the zip codes that you have selected.\n"
	},
	"en-us-real_estate-tv_zip": {
		"launch.preview_ad_label.generic": "Commercial Preview",
		"campaign.ad_noun_descriptor": "commercial",
		"campaign.renewal_modal_headline": "Stay in the spotlight, keep your TV commercial running!",
		"areas.faq.facebook_targeting.question": "Who will see my commercial?",
		"areas.faq.facebook_targeting.answer": "Your commercial will target potential buyers and sellers who live in the zip codes that you are targeting.\n",
		"areas.stats.how_many_prompt": "How many times has your commercial been %{past_participle}?",
		"areas.stats.tv_prompt": "Your Streaming TV Commercial Performance",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here"
	},
	"en-us-real_estate": {
		"campaign.ad_noun_descriptor": "ad",
		"concerns.campaign_with_brand_ad_module.default_header": "Your Real Estate Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your Local Real Estate Expert",
		"facebook_page_feed_update_email.referral_invite.generic": "",
		"results.faq.who_sees": "Your ads are targeted specifically to just the people that you uploaded and matched to our system."
	},
	"en-us-real_estate-realtor": {
		"campaign.renewal_modal_headline": "Keep Following Local Prospects \naround the Web with Your Ad in",
		"campaign.renewal_modal_cta": "Keep My Zip Codes",
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "zip code ads",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx for my zip codes and thought you might be interested in it.\n\n%{root_url}realtor/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite.",
		"results.faq.who_sees": "Potential buyers and sellers who live in the zip codes that you are targeting.",
		"landing_page.check_zip_header": "Check Zip Codes",
		"landing_page.cta_button": "Check Zip Code Availability",
		"landing_page.sample_screens.local_audience.zip_code_simple_pricing": "Target zip codes with simple pricing.",
		"landing_page.pricing_table.description": "per month / zip code spot"
	},
	"en-us-real_estate-realtor_referral": {
		"campaign.renewal_modal_headline": "Keep Staying Top of Mind with Your Sphere",
		"concerns.campaign_with_brand_ad_module.default_header": "Real Estate Pro",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Buying or selling a home?",
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "ads for their sphere",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx to stay top-of-mind with my sphere and thought you might be interested in it.\n\n%{root_url}sphere/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them a free trial invite."
	},
	"en-us-real_estate-real_estate": {
		"campaign.ad_noun_descriptor": "listing ad",
		"facebook_page_feed_update_email.referral_invite.ad_type_description": "listing ads",
		"facebook_page_feed_update_email.referral_invite.body_text": "Hi! I'm trying out some advertising from Adwerx for listings and thought you might be interested in it.\n\n%{root_url}real_estate/more_info",
		"facebook_page_feed_update_email.referral_invite.link_text": "Click here to email them an invite.",
		"results.faq.who_sees": "Potential buyers who are looking for a home in the same area as %{listing}."
	},
	"en-us-real_estate-real_estate_broker_recruiting": {
		"place_holder": ""
	},
	"en-us-generic-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your tagline here",
		"concerns.campaign_with_brand_ad_module.default_header": "Your headline here"
	},
	"en-us-geo-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-insurance-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-local_business-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-mortgage-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-real_estate-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-wealth_management-retargeting": {
		"results.faq.who_sees": "Your ads are retargeting people who have visited your web page with the goal of getting them to come back again.\n"
	},
	"en-us-wealth_management-wealth_management_referral": {
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your local financial pro",
		"results.faq.who_sees": "Your ads are targeted specifically to just the people that you uploaded and matched to our system."
	},
	"en-us-wealth_management-wealth_management_consumer": {
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your local financial pro",
		"results.faq.who_sees": "Potential buyers and sellers who live in the zip codes that you are targeting."
	},
	"en-us-wealth_management-wealth_management_retargeting": {
		"placeholder": "",
		"concerns.campaign_with_brand_ad_module.default_tagline": "Your local financial pro",
		"results.faq.who_sees": "Your ads are targeted to your website visitors."
	}
};