// https://gist.github.com/juliocesar/926500/85cbf5924c071f23772d73dd51ebd9d5d79ec225
// because safari private mode throws an error when using localStorage, sessionStorage
/* eslint-disable */
function polyfillStorageAdapter(storageAdapter) {
  let storageData = {};
  Object.assign(storageAdapter, {
    setItem: function (id, val) {
      return (storageData[id] = String(val));
    },
    getItem: function (id) {
      return storageData.hasOwnProperty(id) ? storageData[id] : undefined;
    },
    removeItem: function (id) {
      return delete storageData[id];
    },
    clear: function () {
      return (storageData = {});
    }
  });
}

(function (canLocalStore) {
  if (!canLocalStore) {
    polyfillStorageAdapter(window.localStorage);
    polyfillStorageAdapter(window.sessionStorage);
  }
})(
  (function () {
    try {
      window.localStorage.setItem("t", 1);
      window.localStorage.removeItem("t");
      return true;
    } catch (e) {
      return false;
    }
  })()
);
/* eslint-enable */
