import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import request from "common/request";

const propTypes = {
  id: PropTypes.number.isRequired
};

class CookieNotice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { closed: false };
    this.close = this.close.bind(this);
  }
  close() {
    request.post("/dismiss_message", { id: this.props.id });
    this.setState({ closed: true });
  }
  render() {
    return (
      <Collapse isOpen={!this.state.closed}>
        <div className="fixed-bottom w-100 bg-gray-700">
          <div className="text-gray-300 p-2 d-flex align-items-center justify-content-center">
            <div>
              This site uses cookies. More info can be found in the{" "}
              <a href="/privacy" className="footer__link--light">
                privacy policy
              </a>
            </div>
            <div className="ml-2 ml-sm-3">
              <button
                onClick={this.close}
                className="btn btn-info text-uppercase"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </Collapse>
    );
  }
}

CookieNotice.propTypes = propTypes;

export default CookieNotice;
