import $ from "jquery";
import React from "react";
import { render } from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import translations from "translations.json"; // eslint-disable-line

// add more locales here, make async when there are many
addLocaleData(require("react-intl/locale-data/en"));

export default function renderComponent(Component, el) {
  const $el = $(el);
  const props = $el.data("props");

  if (!$el.length) {
    return false;
  }

  const extendedLocale = props.locale;

  let localeMemo = "";
  const messages = {};

  // merge on each level of the locale segments for fallbacks
  // en = {one: '...'}
  // en-us = {one: '...', two: '...'}
  // en-us-real_estate = {three: '...'}
  // en-us-real_estate-realtor = {one: '...', four: '...'}
  // messages = {one:, two:, three:, four:}
  extendedLocale.split("-").forEach(localeSegment => {
    if (localeMemo) {
      localeMemo = [localeMemo, localeSegment].join("-");
    } else {
      localeMemo = localeSegment;
    }
    Object.assign(messages, translations[localeMemo] || {});
  });

  return render(
    <IntlProvider locale={navigator.language || "en"} messages={messages}>
      <Component {...props} />
    </IntlProvider>,
    $el.get(0)
  );
}
