import Bugsnag from "@bugsnag/js";

if (process.env.NODE_ENV !== "test") {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    enabledReleaseStages: ["production", "staging"],
    releaseStage: process.env.RAILS_ENV,
    appType: "javascript",
    autoDetectErrors: false
  });
}
